import "./App.css";
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/NavBar";
import AppBar from "./components/AppBar";
import WholesaleDashboardPage from "./pages/WholesaleDashboardPage";
import ExternalDashboardPage from "./pages/ExternalDashboardPage";
import OversightPage from "./pages/OversightPage";
import NoPage from "./pages/NoPage";
import {
  Alert,
  createTheme,
  CssBaseline,
  Divider,
  Snackbar,
  ThemeProvider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/nb";
import { RootState } from "./store";
import { setAlert } from "./reducers/globalSlice";
import "./index.css";
import InfoPage from "./pages/InfoPage";

const locale = "nb";
dayjs.locale(locale);

const ErrorDisplayer = () => {
  const alert = useSelector((state: RootState) => state.global.alert);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setTimeout(() => dispatch(setAlert(undefined)), 5000);
  }, [alert, dispatch]);

  if (!alert) return null;
  return (
    <Snackbar
      open={Boolean(alert)}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert severity={alert.severity} variant="filled" sx={{ width: "100%" }}>
        {alert.message}
      </Alert>
    </Snackbar>
  );
};

const theme = createTheme({
  palette: {
    background: {
      default: "#F5F5F5",
    },
    primary: {
      main: "#e27a0e",
    },
    secondary: {
      main: "#CCC",
    },
  },
});

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="draw-area">
          <AppBar />
          <Navbar />
          <ErrorDisplayer />
          <div>
            <Routes>
              <Route path="/" element={<WholesaleDashboardPage />} />
              <Route path="/besu" element={<ExternalDashboardPage />} />
              <Route path="/info" element={<InfoPage />} />
              <Route path="/oversight" element={<OversightPage />} />
              <Route path="*" element={<NoPage />} />
            </Routes>
          </div>
          <Divider sx={{ my: 5 }} />
        </div>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
