//import components
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import UnifiedDashboardComponent from "../components/UnifiedDashboardComponent";
import { NetworkType } from "../components/NetworkType";

dayjs.extend(utc);

const WholesaleDashboardPage = () => {
  const networkId = "fabric";

  return (
    <div className="content-area">
      {UnifiedDashboardComponent(networkId, NetworkType.FABRIC)}
    </div>
  );
};

export default WholesaleDashboardPage;
