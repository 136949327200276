import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { accountMonitoringApi } from "../apiClient";
import { Balance } from "../generated-client";
import { getPollingInterval } from "./Environment";

interface TotalBalancesTableProps {
  networkIdList: string[];
}

export default function TotalBalancesTable({ networkIdList }: TotalBalancesTableProps) {
  const [balances, setBalances] = useState<{ networkId: string; amount: number }[]>([]);

  useEffect(() => {
    const fetchBalances = async () => {
      const newBalances: { networkId: string; amount: number }[] = [];

      for (const networkId of networkIdList) {
        try {
          const response = await accountMonitoringApi.getEscrowAccountBalance(networkId);
          const amount = response.data.amount ?? 0;
          newBalances.push({ networkId, amount });
        } catch (err: unknown) {
          if (err instanceof Error) {
            console.log(err);
          } else {
            console.log("An unexpected error type occurred");
          }
        }
      }

      setBalances(newBalances);
    };

    fetchBalances();

    const intervalId = setInterval(fetchBalances, getPollingInterval());

    return () => clearInterval(intervalId);
  }, [networkIdList]);

  if (!balances.length) return <>Loading...</>;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 200 }} aria-label="balance table">
        <TableHead>
          <TableRow>
            <TableCell>Network ID</TableCell>
            <TableCell align="right">Balance wNOK</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {balances.map((balance, index) => (
            <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell>{balance.networkId}</TableCell>
              <TableCell align="right">{balance.amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
