import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { accountMonitoringApi, oversightApi } from "../apiClient";
import { getPollingInterval } from "../components/Environment";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { Grid } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { Balance } from "../generated-client";
import { BarChart } from "@mui/x-charts/BarChart";
import Box from "@mui/material/Box";
import { NetworkType } from "../components/NetworkType";
import TotalSupplyHistoryView from "../components/TotalSupplyHistoryView";
import SelectExternalNetworkDropdownMenu from "../components/SelectExternalNetworkDropdownMenu";
import TotalBalancesTable from "../components/TotalBalancesTable";

dayjs.extend(utc);

type networkIdType = {
  networkId: string;
};

const OversightPage = () => {
  const [appnokTotalSupply, setAppnokTotalSupply] = useState<number | null>(null);
  const [rnokTotalSupply, setRnokTotalSupply] = useState<number | null>(null);
  const [ownBalance, setOwnBalance] = useState<Balance | null>(null);
  const externalNetworkIdList = ['appnok', 'rnok'];
  const [networkId, setNetworkId] = React.useState<networkIdType>({networkId: "", });
  const networkIdWnok = "fabric";

  useEffect(() => {
    const fetchTotalSupplies = async () => {
      try {
        // Fetch Appnok total supply
        const appnokResponse = await oversightApi.getTotalSupply('appnok');
        setAppnokTotalSupply(appnokResponse.data);
        // Fetch Rnok total supply
        const rnokResponse = await oversightApi.getTotalSupply('rnok');
        setRnokTotalSupply(rnokResponse.data);
      } catch (err: unknown) {
        if (err instanceof Error) {
          console.log(err);
        } else {
          console.log("An unexpected error type occurred");
        }
      }
    };
    fetchTotalSupplies();

    const intervalId = setInterval(fetchTotalSupplies, getPollingInterval());

    return () => clearInterval(intervalId);
  }, [networkId.networkId]);

  useEffect(() => {
    const fetchOwnBalance = async () => {
      try {
        const response =
          await accountMonitoringApi.getEscrowAccountBalance(networkIdWnok);
        setOwnBalance(response.data);
      } catch (err: unknown) {
        if (err instanceof Error) {
          console.log(err);
        } else {
          console.log("An unexpected error type occurred");
        }
      }
    };
    fetchOwnBalance();

    const intervalId = setInterval(fetchOwnBalance, getPollingInterval()); // Fetch every polling interval

    return () => clearInterval(intervalId);
  }, [networkIdWnok]);

  console.log("DEBUG: networkId.networkId: ", networkId.networkId);

  return (
    <div className="content-area">
      <h1>Total wNOK under Escrow</h1>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 200 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>wNOK under Escrow</TableCell>
              <TableCell>{ownBalance?.amount}</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <h1>External Ledger Escrow Balance - current</h1>
      <TotalBalancesTable networkIdList={externalNetworkIdList} />
      <div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
            <BarChart
              dataset={[
                {
                  type: 'Wholesale under escrow vs. External ledger supply',
                  wNOK: ownBalance?.amount,
                  appNOK: appnokTotalSupply,
                  rNOK: rnokTotalSupply,
                }
              ]}
              xAxis={[{ scaleType: "band", dataKey: "type" }]}

              series={[
                { dataKey: "wNOK", valueFormatter: (value) => value.toLocaleString(), stack: "wholesale", label:"Wholesale under Escrow", color:'#f07d00'},
                { dataKey: "appNOK", valueFormatter: (value) => value.toLocaleString(), stack: "external", label:"Total appNOK Supply", color:'#00bcd4'},
                { dataKey: "rNOK", valueFormatter: (value) => value.toLocaleString(), stack: "external", label:"Total rNOK Supply", color:'#003f87'},
              ]}
              height={300}
              margin={{ left: 80 }}
            />

        </Box>
      </div>
      <h1>Escrow/supply balance - history</h1>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <SelectExternalNetworkDropdownMenu
                onSelect={(id) => setNetworkId({ networkId: id })}
                placeholder="Select a network"
              />
            </Grid>
          </Grid>
      {TotalSupplyHistoryView(networkId.networkId)}
    </div>
  );
};

export default OversightPage;
