import { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { infoApi } from "../apiClient";
import { Contract } from "../generated-client";

const ContractsTable = () => {
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [loadingContracts, setLoadingContracts] = useState<boolean>(true);
  const [errorContracts, setErrorContracts] = useState<string | null>(null);

  useEffect(() => {
    infoApi
      .getContractsInfo()
      .then((response: { data: Contract[] }) => {
        setContracts(response.data);
        setLoadingContracts(false);
      })
      .catch((errorBonds: any) => {
        setErrorContracts("Error fetching contracts");
        setLoadingContracts(false);
      });
  }, []);

  console.log("DEBUG: contracts: " + contracts);

  if (loadingContracts) {
    return <div>Loading...</div>;
  }

  if (errorContracts) {
    return <div>{errorContracts}</div>;
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 200 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Contract</TableCell>
            <TableCell align="left">Address</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contracts.map((contract, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{contract.name}</TableCell>
              <TableCell align="left">{contract.address}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ContractsTable;
