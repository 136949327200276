import { LineChart } from "@mui/x-charts/LineChart";
import dayjs, { Dayjs } from "dayjs";
import Box from "@mui/material/Box";
import { Balance } from "../generated-client";

let localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export default function BalancesTimeline(
  balances: Array<Balance> | undefined,
  tFrom: Dayjs | null,
  tTo: Dayjs | null,
  tToBalance: any | undefined,
) {
  if (!balances || !tFrom || !tTo || !tToBalance) return <>Loading</>;

  // check data quality
  if (
    balances.find(
      (b) =>
        b.amount === undefined ||
        b.amount === null ||
        b.timestamp === undefined,
    )
  ) {
    console.error("got invalid balance data", balances);
    return <>Error</>;
  }

  // remove duplicates
  balances = balances.filter(
    (item, index, self) =>
      index ===
      self.findIndex(
        (t) => t.amount === item.amount && t.timestamp === item.timestamp,
      ),
  );

  let times;
  let amounts;

  // check for empty data
  if (balances.length === 0) {
    if (!tToBalance) return <>loading</>;

    amounts = [tToBalance.amount];
    times = [tTo.add(45, "minutes").toDate()];
  } else {
    amounts = balances.map((balance) => balance.amount!).reverse();
    times = balances
      .map((balance) => dayjs(balance.timestamp).local().toDate())
      .reverse();

    amounts = [amounts[0], ...amounts, amounts[amounts.length - 1]];
    times = [
      tFrom.subtract(2, "second").toDate(),
      ...times,
      tTo.add(2, "second").toDate(),
    ];
  }

  console.log("tToBalance:", tToBalance);
  console.log("balances:", balances);
  console.log("Amounts:", amounts);
  console.log("Times:", times);


  return (
    <Box>
      <LineChart
        xAxis={[
          {
            data: times,
            scaleType: "time",
            min: tFrom.toDate(),
            max: tTo.toDate(),
          },
        ]}
        series={[
          {
            curve: "stepAfter",
            data: amounts,
            color: "#f07d00",
          },
        ]}
        height={300}
        margin={{ left: 80 }}
      />
    </Box>
  );
}
