import React, { useState } from "react";
import Select, { SingleValue } from "react-select";

interface SelectExternalNetworkDropdownMenuProps {
  onSelect: (networkId: string) => void;
  placeholder: string;
}

type OptionType = {
    networkId: string;
    label: string;
    value: string;
};

const DropdownMenu: React.FC<SelectExternalNetworkDropdownMenuProps> = ({ onSelect, placeholder }) => {
  const [selectedOption, setSelectedOption] =
    useState<SingleValue<OptionType>>(null);

const options: OptionType[] = [
    {
        networkId: "appnok",
        label: "Besu AppNok",
        value: `appnok`,
    },
    {
        networkId: "rnok",
        label: "Besu Retail",
        value: `rnok`,
    }
];

    const handleChange = (option: SingleValue<OptionType>) => {
      setSelectedOption(option);
      onSelect(option?.networkId ?? "");
    };

    return (
      <Select
        value={selectedOption}
        onChange={handleChange}
        options={options}
        styles={{
          container: (provided, state) => ({
            ...provided,
            width: 280,
            fontFamily: "Open Sans, sans-serif",
          }),
        }}
        placeholder={placeholder}
      />
    );
  };

export default DropdownMenu;
